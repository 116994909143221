<template>
  <div>
    <!-- <div v-if="isLoged">
      <b-container>
        <div v-if="user && user.has_changed_password == false">
          <UpdatePassword></UpdatePassword>
        </div>
        <div v-else>
          <template v-if="$maintenance_view">
            <MaintenanceView></MaintenanceView>
          </template>
        </div>
      </b-container>
      <template
        v-if="user && user.has_changed_password == true && !$maintenance_view"
      >
        <Dashboard class="dashboard-container"></Dashboard>
      </template>
    </div> -->
    <section v-if="app_name == 'KEY LEARNING'">
      <Home> </Home>
    </section>
  </div>
  <!-- </b-container> -->
</template>

<script>
import { APP_NAME } from "@/utils/globals";
import { mapGetters } from "vuex";

export default {
  name: "Welcome",
  components: {
    // Dashboard: () => import("@/components/users/Dashboard"),
    // UpdatePassword: () => import("@/components/users/UpdatePassword"),
    // MaintenanceView: () => import("@/components/public/MaintenanceView"),
    Home: () => import("@/components/new-page/Home"),
  },
  data: () => ({
    app_name: APP_NAME,
  }),
  computed: {
    ...mapGetters({
      isLoged: "isLoged",
      user: "getUser",
      institution: "getInstitution",
    }),
  },
};
</script>

<style>
.dashboard-container {
  width: 100% !important;
}
html {
  scroll-behavior: smooth;
}
input[type="text"] {
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

#generate {
  color: #ffffff !important;
  border-width: 0px !important;
  border-radius: 25px;
  background-color: #006e9a;
  font-size: 20px;
  padding: 6px 16px;
  margin-bottom: 1%;
  margin-right: 1%;
  float: right;
}

#generate:hover {
  background-color: #037faf;
  transition: 0.2s;
}
</style>
